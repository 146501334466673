import React from "react";
import { MapContainer, Marker, TileLayer } from "react-leaflet";

export const Map = (props) => {
  return (
    <div>
      <div id="map">
        <div className="container">
          <div className="col-md-8" style={{ marginBottom: "0" }}>
            <div className="row">
              <div className="section-title">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <h2>Dove siamo</h2>
                </div>
                <div style={{ color: "grey" }}>
                  <p>
                    Nella fantastica Isola Di Ischia, in provincia di Napoli
                  </p>
                  <p>
                    CORSO VITTORIO EMANUELE 90, 80074, Casamicciola Terme,
                    Napoli
                  </p>
                </div>
              </div>
            </div>
          </div>

          {props.data && (
            <MapContainer
              center={[props.data.latitude, props.data.longitude]}
              zoom={13}
              style={{ height: "50rem", width: "100%", marginBottom: "2rem" }}
            >
              <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              />
              <Marker
                position={[props.data.latitude, props.data.longitude]}
              ></Marker>
            </MapContainer>
          )}

          <div
            onClick={() =>
              window.open(
                `https://www.google.com/maps?q=${props.data.latitude},${props.data.longitude}`,
                "_blank"
              )
            }
            className="btn btn-custom btn-lg page-scroll"
            style={{ border: "1px solid black" }}
          >
            Apri navigazione
          </div>
        </div>
      </div>
    </div>
  );
};
