import React from "react";

export const Header = (props) => {
  return (
    <header id="header">
      <div className="intro">
        <div className="overlay">
          <div className="container">
            <div className="row">
              <div className="col-md-8 col-md-offset-2 intro-text">
                <div
                  style={{
                    background:
                      "linear-gradient(to right, #6372ff 0%, #5ca9fb 100%)",
                    borderRadius: "30px",
                    padding: "2rem",
                  }}
                >
                  <h1 style={{ textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)" }}>
                    {props.data ? props.data.title : "Loading"}
                    <span></span>
                  </h1>
                  <p style={{ fontWeight: "bold" }}>
                    {props.data ? props.data.paragraph : "Loading"}
                  </p>
                  <a
                    href="#contact"
                    className="btn btn-custom btn-lg page-scroll"
                    style={{ border: "1px solid black" }}
                  >
                    contattaci
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
